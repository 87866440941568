import { DIV, DIVWithClick, SPAN, P } from "./dom";
import {
  clearCategory,
  get,
  getCategories,
  getSelectedCategory,
  getColorForCategory,
  setCategory,
  ActionType,
} from "./state";
import { fromLinguaRecord, tr } from "./locale";

// const renderNoCategory = () => {
//   const picto = SPAN("color", "");
//   picto.style.background = get("colorDefault");
//   return DIV("legend-element unknown", picto, SPAN("label", tr("unknown")));
// };

// export const renderColorLegend = (legend: Legend) =>
//   DIV(
//     "legend color",
//     ...getCategoriesForLegend(legend).map((cat, i) => {
//       const className = get("category") === cat ? "selected" : "unselected";
//       const picto = SPAN("color", "");
//       picto.style.backgroundColor = getColorForCategory(cat);
//       picto.style.stroke = "1px";
//       return DIV(`legend-element ${className}`, picto, SPAN("label", tr(cat)));
//     })
//     // renderNoCategory()
//   );

const switchCategory = (cat: string) =>
  getSelectedCategory() === cat ? clearCategory() : setCategory(cat);

const regionalStreetPicto = () => {
  const picto = SPAN("color-line", "");
  picto.style.backgroundColor = get("regionalStreetColor");
  picto.style.stroke = "1px";
  return DIV("legend-element", picto, SPAN("label", tr("regional_street")));
};

const sizePicto = () => {
  // const picto = SPAN("color-circle", "");
  // picto.style.backgroundColor = get("colorDefault");
  // picto.style.stroke = "1px";
  // return DIV("legend-element", picto, SPAN("label", tr("size_legend")));
  // return IMG("size-circles", "../style/assets/picto-cercle.svg");
  const picto = DIV("picto-circles");
  return DIV(
    "legend-element circles",
    picto,
    SPAN("range", P("max", ">100"), P("min", "1")),
    SPAN("label", tr("actions number"))
  );
};

export const colorCircle = (cat: ActionType) => {
  const picto = SPAN("color-circle", "");
  picto.style.backgroundColor = getColorForCategory(cat.id);
  picto.style.stroke = "1px";
  return picto;
};

export const renderColorLegend = () =>
  DIV(
    "legend filter color",
    ...getCategories().map((cat) => {
      const catId = cat.id;
      const className =
        getSelectedCategory() === catId ? "selected" : "unselected";

      return DIV(
        `legend-element ${className}`,
        DIV(
          "legend-item-wrapper",
          colorCircle(cat),
          DIVWithClick(
            `label`,
            () => switchCategory(catId),
            fromLinguaRecord(cat.name)
          )
        )
        // getLegendExpendedOpt().map(() =>
        //   DIV("type-description", fromLinguaRecord(cat.description))
        // )
      );
    })
  );

export const renderInactiveLegend = () =>
  DIV(
    "legend inactive",

    sizePicto(),
    regionalStreetPicto(),
    DIV("data-infos", tr("regional_street_info"))
  );
