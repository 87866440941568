import { Coordinate } from "ol/coordinate";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import { MultiLineString } from "ol/geom";

import { boolToOpt, fromNullable, fromPredicate } from "./option";
import { LinguaRecord } from "./locale";
import {
  IToolGeocoder,
  IUgWsResponse,
  defaultToolGeocoder,
} from "./geocoder/types";

export type Nullable<T> = T | null;

export interface Street {
  id: number;
  name: LinguaRecord;
  geometry: MultiLineString;
}
export const isStreet = (obj: any): obj is Street => {
  return "id" in obj && "name" in obj;
};

// was called "category" before... so there are still traces of it
export interface ActionType {
  id: string;
  name: LinguaRecord;
  description: LinguaRecord;
}

export interface DataProps {
  id: number;
  street: Street;
  types: ActionType[];
  counts: number[];
  descriptions: LinguaRecord[][];
}
export type PropKey = keyof DataProps;

export type Lang = "fr" | "nl";
const isLang = (l: unknown): l is Lang =>
  typeof l === "string" && (l === "fr" || l === "nl");

// export type Legend = "prevention" | "gestion";
// export const legends: Legend[] = ["prevention", "gestion"];

// TODO: get this from the backend :)
// export type Category =
//   | "Intervention"
//   | "Taxe"
//   | "Balayage"
//   | "Médiateur"
//   | "Container";

// legends.reduce(
//   (acc, leg) => acc.concat(getCategoriesForLegend(leg)),
//   [] as Category[]
// );

export interface StateView {
  center: Coordinate;
  zoom: number;
  dirty?: string;
  maxZoom: number;
  minZoom: number;
}

interface State {
  lang: Lang;
  features: Feature[];
  streets: Street[];
  // legend: Legend;
  category: Nullable<string>;
  colors: string[];
  regionalStreetColor: string;
  colorDefault: string;
  radiusDefault: number;
  // categories: { [key in Legend]: string[] };
  categories: ActionType[];
  form: { [key: string]: string | number };
  geocoder: IToolGeocoder;
  mapView: StateView;
  legend_expended: boolean;
}

type StateKey = keyof State;

const state: State = {
  lang: fromPredicate<Lang>(isLang)((window as any).appLanguage).getOrElse(
    "fr"
  ),
  features: [],
  streets: [],
  // legend: "prevention",
  category: null,
  colors: [
    "#FDC300", //jaune
    "#a230ff", //mauve
    "#f37037", //orange
    "#72dd08", //vert
    "#61b5ff", //bleu
    "#3b08ae", //mauve foncé
    "#00537F", //bleu
    "#08B0A0", //vert bleu
    "#00537F", //bleu
    "#E71F69", //rouge
    "#A2C73A", //vert vif
    "#CE7D88", //rose
    "#EE743B", //orange-2
    "#D81B60",
    "#1E88E5",
    "#FF9C3A",
    "#165923",
    "#42DD73",
    "#FBA3EE",
    "#9325E0",
    "#186B73",
    "#BFE85E",
    "#2710EF",
    "#F5E6A5",
    "#B70707",
    "#64DEEC",
    "#001392",
    "#719945",
    "#9175F3",
    "#3D3029",
    "#740557",
    "#36A5A0",
  ],
  regionalStreetColor: "#e0e0e0", //gris
  // regionalStreetColor: "#FDC300", //jaune
  colorDefault: "#595959",
  radiusDefault: 6,
  categories: [],
  // prevention: ["intervention", "taxe", "balayage"] as Category[],
  // gestion: ["mediateur", "container"] as Category[],
  form: {},
  legend_expended: false,
  mapView: {
    center: fromLonLat([4.38, 50.8621987]),
    zoom: 15,
    maxZoom: 19,
    minZoom: 14,
  },
  geocoder: defaultToolGeocoder(),
};
const observers: [key: StateKey, fn: () => void][] = [];

export const get = <K extends StateKey>(key: K): State[K] => state[key];

export const set = <K extends StateKey>(key: K, val: State[K]) => {
  console.log(key);
  state[key] = val;
  observers.forEach(([okey, fn]) => {
    if (key === okey) {
      fn();
    }
  });
};

export const dispatch = <K extends StateKey, T>(
  key: K,
  update: (s: State[K]) => State[K]
) => {
  const state = get(key);
  set(key, update(state));
};

export const observe = (key: StateKey, fn: () => void) =>
  observers.push([key, fn]);

// QUERIES -----------------------

export const getLang = () => get("lang");
export const getStreets = () => get("streets");
export const getStreetNames = () => getStreets().map((s) => s.name[getLang()]);
export const getStreetFromName = (name: string) =>
  fromNullable(getStreets().find((s) => s.name[getLang()] === name));

export const getView = () => {
  const stateView = get("mapView");
  return new View({
    center: stateView.center,
    zoom: stateView.zoom,
    maxZoom: stateView.maxZoom,
    minZoom: stateView.minZoom,
  });
};

export const getColor = (i: number) => {
  if (i < 0) {
    return get("colorDefault");
  }
  const colors = get("colors");
  const colorIndex = i % colors.length;
  return colors[colorIndex];
};

export const getCategories = () => get("categories");
export const getCategoryNames = () => getCategories().map((c) => c.id);
export const getSelectedCategory = () => get("category");

export const getLegendExpended = () => get("legend_expended");
export const getLegendExpendedOpt = () => boolToOpt(getLegendExpended());

export const getColorForCategory = (category: string) =>
  getColor(getCategoryNames().indexOf(category));

// export const getCategoriesForLegend = (legend: Legend) =>
//   getCategories()[legend] as Category[];

export const getGeocoderUrl = (lang: string, address: string) =>
  `https://geoservices.irisnet.be/localization/Rest/Localize/getaddresses?=&spatialReference=31370&language${lang}=&address=${address}`;

export const toolsGeocoder = () => get("geocoder");
export const getSelectedFeatures = () => get("features");

// EVENTS -------------------------

export const setStreets = (streets: Street[]) => set("streets", streets);
export const setLegendExpended = (expended: boolean) =>
  set("legend_expended", expended);
export const switchLegendExpended = () =>
  set("legend_expended", !getLegendExpended());

export const setCategories = (categories: ActionType[]) =>
  set("categories", categories);
export const setCategory = (categoryName: string) =>
  set("category", categoryName);
export const clearCategory = () => set("category", null);

export const updateGeocoderResponse = (
  serviceResponse: IUgWsResponse | null
) => {
  dispatch("geocoder", (state) => {
    state.serviceResponse = serviceResponse;
    return state;
  });
};

export const updateGeocoderTerm = (address: string) => {
  dispatch("geocoder", (state) => ({ ...state, address }));
};

export const unfoldGeocoder = () => {
  dispatch("geocoder", (state) => {
    state.folded = false;
    return state;
  });
};

export const foldGeocoder = () => {
  dispatch("geocoder", (state) => {
    state.folded = true;
    return state;
  });
};

export const updateMapView = (stateView: StateView) => {
  set("mapView", stateView);
};

export const setSelectedFeatures = (features: Feature[]) =>
  set("features", features);
