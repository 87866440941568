import { FeatureLike } from "ol/Feature";
import { Style, Circle, Fill, Stroke } from "ol/style";
import { MultiPoint, MultiLineString, Point } from "ol/geom";

import { fromNullable, fromPredicate } from "./option";
import {
  ActionType,
  getColorForCategory,
  getSelectedCategory,
  getStreetFromName,
} from "./state";

const positivePredicate = fromPredicate<number>((num) => num >= 0);

export const circle = (radius: number, color: string) =>
  new Style({
    image: new Circle({
      radius,
      fill: new Fill({ color }),
    }),
  });

export const defaultStyle = circle(2, "grey");

export const selectStyle = (_f: FeatureLike) => circle(30, "red");

interface OffsetOption {
  offsetLeft: number;
  offsetRight: number;
  nextSide: "right" | "left";
}

const nextSider = () => {
  let side: "right" | "left" = "left";
  const next = () => {
    side = side == "right" ? "left" : "right";
    return side;
  };

  return next;
};

const radius = (count: number, res: number) => {
  if (count <= 0) {
    return 0;
  }
  return Math.min(20, 4 + (0.1 * Math.min(count, 100)) / (res / 2));
};

export const multiLineLength = (multiline: MultiLineString) =>
  multilineLengths(multiline).reduce((val, acc) => acc + val, 0);

const multilineLengths = (multiline: MultiLineString) =>
  multiline.getLineStrings().map((l) => l.getLength());

const nextPoint = (street: MultiLineString, offsetOptions: OffsetOption) => {
  let sumLength = 0;
  const halfDistance = multiLineLength(street) / 2;
  const offset =
    offsetOptions.nextSide === "right"
      ? offsetOptions.offsetRight
      : -offsetOptions.offsetLeft;
  const distanceToPoint = halfDistance + offset;
  const lines = street.getLineStrings();
  const middleLine = lines.reduce((line, acc) => {
    if (sumLength < distanceToPoint) {
      sumLength = sumLength + line.getLength();
      return line;
    }
    return acc;
  }, lines[0]);
  const distOnMiddleLine = middleLine.getLength() + distanceToPoint - sumLength;

  // find point on that middleline at distOnMiddleLine distance
  const [x0, y0] = middleLine.getFirstCoordinate();
  const [x1, y1] = middleLine.getLastCoordinate();
  const lineLength = middleLine.getLength();
  const t = distOnMiddleLine / lineLength;
  const newPoint = [(1 - t) * x0 + t * x1, (1 - t) * y0 + t * y1];
  const pointOnMultiline = street.getClosestPoint(newPoint);
  return new Point(pointOnMultiline);
};

const getCircleStyle = (
  radius: number,
  color: string,
  geometry: Point,
  stroke: Stroke | null
) =>
  fromNullable(stroke).fold(
    () =>
      new Style({
        image: new Circle({
          radius: radius,
          fill: new Fill({
            color: color,
          }),
        }),
        geometry: geometry,
      }),
    (stroke) =>
      new Style({
        image: new Circle({
          radius: radius,
          fill: new Fill({
            color: color,
          }),
          stroke: stroke,
        }),
        geometry: geometry,
      })
  );

export const genericStyle = (
  multipoint: FeatureLike,
  res: number,
  stroke: Stroke | null = null
) => {
  const styles: Style[] = [];
  const geom = multipoint.getGeometry();
  const props = multipoint.getProperties();

  const allCounts = props["counts"] as (number | null)[];
  const allTypes = props["types"] as ActionType[];
  const filterPositive = (arr: any[]) =>
    arr.filter((_val, i) =>
      fromNullable(allCounts[i])
        .map((c) => c && c > 0)
        .getOrElse(false)
    );
  const counts: number[] = filterPositive(allCounts);
  const types: ActionType[] = filterPositive(allTypes);

  const streetOpt = getStreetFromName(props["street"]).map((s) => s.geometry);

  const offsetOptions: OffsetOption = {
    offsetLeft: 0,
    offsetRight: 0,
    nextSide: "left",
  };

  const nextSide = nextSider();

  if (geom?.getType() == "MultiPoint") {
    const g = geom as MultiPoint;
    const points = filterPositive(g.getPoints());

    // if there is a selected category, only draws the corresponding points
    const selectedCat = getSelectedCategory();
    const catIndexOpt = fromNullable(selectedCat)
      .map((sc) => types.map((t) => t.id).indexOf(sc))
      .chain((n) => positivePredicate(n));

    if (selectedCat != null) {
      catIndexOpt.map((catIndex) =>
        styles.push(
          getCircleStyle(
            radius(counts[catIndex], res),
            getColorForCategory(types[catIndex].id),
            points[catIndex],
            stroke
          )
        )
      );
      return styles;
    }

    // otherwise: draw one point per category
    points.map((p, i) => {
      offsetOptions.nextSide = nextSide();
      if (i === 0) {
        styles.push(
          getCircleStyle(
            radius(counts[i], res),
            getColorForCategory(types[i].id),
            p,
            stroke
          )
        );
        offsetOptions.offsetLeft = radius(counts[0], res);
        offsetOptions.offsetRight = radius(counts[0], res);
      } else {
        if (offsetOptions.nextSide === "right") {
          offsetOptions.offsetRight =
            offsetOptions.offsetRight + radius(counts[i], res) + 12;
        } else {
          offsetOptions.offsetLeft =
            offsetOptions.offsetLeft + radius(counts[i], res) + 12;
        }
        streetOpt.map((street) =>
          styles.push(
            getCircleStyle(
              radius(counts[i], res),
              getColorForCategory(types[i].id),
              nextPoint(street, offsetOptions),
              stroke
            )
          )
        );
      }
    });
  }

  return styles;
};

export const multipointStyle = (
  multipoint: FeatureLike,
  res: number
): Style | Style[] => genericStyle(multipoint, res);

export const multipointStyleSelect = (
  multipoint: FeatureLike,
  res: number
): Style | Style[] =>
  genericStyle(multipoint, res, new Stroke({ color: "black", width: 3 }));
