import { Feature } from "ol";
import { updateInfo } from "./index";
import { DIV, emptyElement, H2, H3, DIVWithClick, UL, LI } from "./dom";
import { tr } from "./locale";
import type { DataContext } from "./index";

const renderFeatureList = (
  features: Feature[],
  selectAction: (id: number) => void,
  dataContext: DataContext
) =>
  DIV(
    "feature-list",
    H3("subtitle", tr("selected_actions")),
    UL(
      "list",
      ...features.map((f) =>
        dataContext.getFeatureID(f).map((id) =>
          LI(
            "feature-item",
            DIVWithClick(
              "",
              () => selectAction(id),
              dataContext.getFeatureName(f).getOrElse(id.toString())
            )
          )
        )
      )
    )
  );

export const featuresInfoFactory =
  (parent: Element, dataContext: DataContext) =>
  (features: Feature[], selectAction: (id: number) => void) => {
    emptyElement(parent);
    if (features.length >= 1) {
      const summary = DIV(
        "summary",
        H2("summary-title", tr("aggregated_data"), ` (${features.length})`),
        renderFeatureList(features, selectAction, dataContext)
      );
      parent.appendChild(summary);
      // } else if (features.length == 1) {
      //   featureInfoFactory(parent)(features);
    } else {
      updateInfo(parent);
    }
  };
