export interface Foldable {
  folded: boolean;
}

export interface IUgWsAddress {
  street: {
    name: string;
    postCode: string;
    municipality: string;
    id: string;
  };
  number: string;
}

export interface IUgWsPoint {
  x: number;
  y: number;
}

export interface IUgWsExtent {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export interface IUgWsQualificationText {
  policeNumber: string;
  postCode: string;
  municipality: string;
  streetName: string;
}

export interface IUgWsQualificationCode {
  policeNumber: string;
  postCode: string;
  municipality: string;
  streetName: string;
}

export interface IUgWsResult {
  language: string; // TODO?
  address: IUgWsAddress;
  adNc: string;
  score: number;
  point: IUgWsPoint;
  extent: IUgWsExtent;
  qualificationText: IUgWsQualificationText;
  qualificationCode: IUgWsQualificationCode;
}

export interface IUgWsResponse {
  result: IUgWsResult[];
  error: boolean;
  status: string;
  version: string;
}
export interface IUgWsResponseSingle {
  result: IUgWsResult;
  error: boolean;
  status: string;
  version: string;
}

export interface IToolGeocoder extends Foldable {
  address: string;
  serviceResponse: IUgWsResponse | null;
}

export const defaultToolGeocoder = (): IToolGeocoder => ({
  folded: true,
  address: "",
  serviceResponse: null,
});
