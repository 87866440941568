import Feature from "ol/Feature";
import { AcNode, BUTTON, DIV, H3, SPAN, emptyElement } from "./dom";
import {
  DataProps,
  PropKey,
  getLegendExpended,
  getLegendExpendedOpt,
  switchLegendExpended,
} from "./state";
import { fromLinguaRecord, tr, trMulti } from "./locale";
import {
  fromNullable,
  fromPredicate,
  notEmpty,
  notEmptyStr,
  Option,
} from "./option";
import type { DataContext } from "./index";
import { colorCircle } from "./legend";

type KeyTitle = "generalities";

type KeyGroup = { title: KeyTitle; keys: PropKey[] };

// const KEYS: KeyGroup[] = [
//   {
//     title: "generalities",
//     // keys: ["id", "type", "count", "description"],
//     keys: ["id", "counts", "types", "street"],
//   },
// ];

const renderKey = (key: PropKey): AcNode => DIV(`key ${key}`, tr(key));

type Base = string | number;
const renderBool = (key: PropKey, n: Base) => {
  const isTrue = n === 1;
  const color = isTrue ? "black" : "gray";
  const node = DIV(
    `kv ${isTrue} ${key}`,
    renderKey(key),
    DIV(`value ${typeof n}`, tr(n.toString()))
  );
  node.style.color = color;
  return node;
};

const renderString = (key: PropKey, s: Base) =>
  DIV(
    `kv ${key}`,
    renderKey(key),
    DIV(`value ${typeof s}`, trMulti(s.toString()))
  );

const renderNumber = (key: PropKey, n: Base) =>
  DIV(
    `kv ${key}`,
    renderKey(key),
    DIV(`value ${typeof n}`, n.toLocaleString("fr-BE"))
  );

type RenderType = "string" | "number" | "boolean";

// export const renderType: { [key in PropKey]?: RenderType } = {
//   id: "number",
//   counts: "number[]",
//   // street: "string"
//   // description: "string",
// };

// const makeGroup = (
//   keyGroup: KeyGroup,
//   f: Feature,
//   dataContext: DataContext
// ) => {
//   // const title = H3("info-title", tr(keyGroup.title));
//   const keys: AcNode[] = [];
//   keyGroup.keys.forEach((k) =>
//     makeItem(k, f, dataContext).map((i) => keys.push(i))
//   );
//   return DIV("info-group", DIV("info-keys", keys));
// };

// const makeItem = (key: PropKey, f: Feature, dataContext: DataContext) => {
//   const props: Option<DataProps> = dataContext.getProps(f);
//   const value = props.chain((p) => fromNullable(p[key]));
//   return value.map((v) => {
//     switch (renderType[key]) {
//       case "string":
//         return renderString(key, v);
//       case "number":
//         return renderNumber(key, v);
//       case "boolean":
//         return renderBool(key, v);
//       default:
//         return renderString(key, v);
//     }
//   });
// };
const notNullOpt = fromPredicate<number>((val) => val > 0);

const renderMainInfo = (f: Feature, dataContext: DataContext) => {
  const props: Option<DataProps> = dataContext.getProps(f);

  return props.map((p) => {
    const s = p.street;
    const ts = p.types;
    const c = p.counts;
    const featureDescriptions = p.descriptions;
    const l = ts.map((t, i) => {
      const actionStreetDescriptions = featureDescriptions[i];
      return notNullOpt(c[i]).map((count) =>
        DIV(
          "action-infos",
          DIV(
            "count",
            colorCircle(t),
            SPAN("count-number", ` ${count} `),
            SPAN("count-label", `${fromLinguaRecord(t.name)}`)
          ),
          getLegendExpendedOpt().map(() =>
            DIV("type-description", fromLinguaRecord(t.description))
          ),

          notEmpty(actionStreetDescriptions).chain((descList) => {
            const lastActionDescription =
              descList[actionStreetDescriptions.length - 1];
            return fromNullable(fromLinguaRecord(lastActionDescription))
              .chain((d) => notEmptyStr(d))
              .map((d) =>
                DIV("description", `${tr("last_description")}: ${d}`)
              );
          })
        )
      );
    });
    return DIV(`main-info`, H3("street", fromLinguaRecord(s.name)), ...l);
  });
};

const buttonText = () =>
  getLegendExpended() ? tr("see_less") : tr("see_more");

export const expandButton = () =>
  BUTTON("more", switchLegendExpended, buttonText());

export const featureInfoFactory =
  (parent: Element, dataContext: DataContext) => (features: Feature[]) => {
    emptyElement(parent);
    features.map((feature) => {
      const featureElem = DIV(
        "feature-wrapper",
        renderMainInfo(feature, dataContext)
      );
      // KEYS.forEach((k) =>
      //   featureElem.appendChild(makeGroup(k, feature, dataContext))
      // );
      parent.appendChild(featureElem);
      parent.appendChild(expandButton());
    });
    return parent;
  };
