import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON, { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import TileWMS from "ol/source/TileWMS";
import Select, { SelectEvent } from "ol/interaction/Select";
import { click } from "ol/events/condition";
import { Feature } from "ol";
import { FeatureLike } from "ol/Feature";
import { Coordinate } from "ol/coordinate";
import MultiLineString from "ol/geom/MultiLineString";
import { MultiPoint } from "ol/geom";
import Point from "ol/geom/Point";

import { DIV, emptyElement } from "./dom";
import {
  multipointStyle,
  multipointStyleSelect,
  multiLineLength,
} from "./map-style";
import {
  set,
  observe,
  PropKey,
  DataProps,
  Street,
  isStreet,
  setCategories,
  getView,
  clearCategory,
  ActionType,
  getSelectedFeatures,
  setSelectedFeatures,
  setStreets,
} from "./state";
import { renderColorLegend, renderInactiveLegend } from "./legend";
import { featuresInfoFactory } from "./summary";
import { featureInfoFactory } from "./indivi";

import { LinguaRecord, fromLinguaRecord, tr } from "./locale";
import { fromNullable, none, notEmpty, Option } from "./option";
import { tryNumber } from "./util";
import geocoder from "./geocoder/index";

// const legendSwitchButton = RADIOBTN(
//   "switch-legend",
//   (e: Legend) => DIV("legend-option", tr(e)),
//   (e: Legend) => {
//     set("legend", e);
//     set("category", null);
//   }
// );

// const renderSwitchLegend = () =>
//   legendSwitchButton("switch-legend", legends, get("legend"));

export const format = new GeoJSON({
  featureProjection: "EPSG:3857",
  dataProjection: "EPSG:4326",
});

const intro = () => DIV("intro", tr("helptext:selectOnMap"));

export const updateInfo = (info: Element, features: Feature[] = []) => {
  emptyElement(info);
  // get("category") === null
  //   ? info.appendChild(intro())
  //   : info.appendChild(noFeatures());
  info.appendChild(intro());
  // notEmpty(features).map(

  // )
};
const updateLegend = (legend: Element) => {
  emptyElement(legend);
  legend.appendChild(
    DIV("legendContent", renderColorLegend(), renderInactiveLegend())
  );
};

export type DataContext = {
  getProps: (f: FeatureLike) => Option<DataProps>;
  getFeatureProp: (
    f: Feature,
    key: PropKey
  ) => Option<
    | string
    | number
    | string[]
    | number[]
    | Street
    | LinguaRecord[][]
    | ActionType[]
  >;
  getFeatureID: (f: Feature) => Option<number>;
  getFeatureName: (f: Feature) => Option<string | number>;
  isFromCategory: (
    feature: FeatureLike,
    catKey: PropKey,
    catValue: string
  ) => boolean;
};

const makeContext = (
  actionsCollection: GeoJSONFeatureCollection
): DataContext => {
  const actions = actionsCollection.features;
  const getFeatureProp = (f: Feature, key: PropKey) => {
    const props = getProps(f);
    return props.chain((p) => fromNullable(p[key]));
  };
  const getProps = (f: FeatureLike): Option<DataProps> => {
    const id = f.getId();
    return fromNullable(actions.find((f) => f.id === id)).map(
      (f) => f.properties as DataProps
    );
  };

  const getFeatureID = (f: Feature) =>
    getFeatureProp(f, "id").chain((id) => tryNumber(id));
  const getFeatureName = (f: Feature) =>
    getFeatureProp(f, "street").map((value) =>
      isStreet(value) ? fromLinguaRecord(value.name) : value
    ) as Option<string | number>;

  const isFromCategory = (
    feature: FeatureLike,
    catKey: PropKey,
    catValue: string
  ) =>
    getProps(feature)
      .chain((f) => fromNullable(f[catKey]))
      .map((fcat) => fcat.toString().includes(catValue))
      .getOrElse(false);

  return {
    getFeatureProp,
    getProps,
    getFeatureID,
    getFeatureName,
    isFromCategory,
  };
};

// const multiLineLength = (multiline: MultiLineString) =>
//   multilineLengths(multiline).reduce((val, acc) => acc + val, 0);

// const multilineLengths = (multiline: MultiLineString) =>
//   multiline.getLineStrings().map((l) => l.getLength());

const getPointOnMultiline = (
  multiline: MultiLineString
  // offsetOptions: OffsetOption
): Option<Point> => {
  let sumLength = 0;
  const halfDistance = multiLineLength(multiline) / 2;
  // const offset =
  //   offsetOptions.nextSide === "right"
  //     ? offsetOptions.offsetRight
  //     : -offsetOptions.offsetLeft;
  // const distanceToPoint = halfDistance + offset;
  const distanceToPoint = halfDistance;
  const lines = notEmpty(multiline.getLineStrings());
  return lines.map((l) => {
    const middleLine = l.reduce((line, acc) => {
      if (sumLength < distanceToPoint) {
        sumLength = sumLength + line.getLength();
        return line;
      }
      return acc;
    }, l[0]);
    const distOnMiddleLine =
      middleLine.getLength() - (sumLength - distanceToPoint);

    // find point on that middleline at distOnMiddleLine distance
    const [x0, y0] = middleLine.getFirstCoordinate();
    const [x1, y1] = middleLine.getLastCoordinate();
    const lineLength = middleLine.getLength();
    const t = distOnMiddleLine / lineLength;
    const newPoint = [(1 - t) * x0 + t * x1, (1 - t) * y0 + t * y1];
    const pointOnLine = multiline.getClosestPoint(newPoint);
    return new Point(pointOnLine);
  });
};

// TODO
// type app = "map" | "form"; // make a switch in main
const makeMultiPoints = (streetActions: GeoJSONFeatureCollection) => {
  const getPointCoord = (multiline: MultiLineString) =>
    getPointOnMultiline(multiline).map((point) => point.getFirstCoordinate());

  const features: Feature<MultiPoint>[] = [];
  const streets: Street[] = [];

  streetActions.features.map((af) => {
    fromNullable(af.properties).map((p) => {
      const props = p as DataProps;
      const pts: Coordinate[] = [];
      const geom = af.geometry;
      if (geom.type == "MultiLineString") {
        const streetGeom = format
          .readFeature(af)
          .getGeometry() as MultiLineString;

        streets.push({ ...props.street, geometry: streetGeom });
        getPointCoord(streetGeom).map((pt) =>
          props.types.map(() => pts.push(pt))
        );
        const feature = new Feature({
          fid: af.id,
          counts: p.counts,
          types: p.types,
          descriptions: p.descriptions,
          street: fromLinguaRecord(p.street.name),
          geometry: new MultiPoint(pts),
        });
        feature.setId(af.id);
        features.push(feature);
      }
    });
  });
  setStreets(streets);
  return features;
};

const main = (
  streetActions: GeoJSONFeatureCollection,
  categories: ActionType[]
) => {
  const dataContext = makeContext(streetActions);
  setCategories(categories);

  const multiPoints = makeMultiPoints(streetActions);
  const vectorSource = new VectorSource({
    features: multiPoints,
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    // style: selectStyle,
    style: multipointStyle,
  });

  const select = new Select({
    condition: click,
    style: multipointStyleSelect,
    // style: selectStyle,
    layers: [vectorLayer],
  });

  const getFeaturesFromCat = (catKey: PropKey, catValue: string) =>
    fromNullable(vectorLayer.getSource())
      .map((s) =>
        s
          .getFeatures()
          .filter((f) => dataContext.isFromCategory(f, catKey, catValue))
      )
      .getOrElse([]);

  const getFeatureFromId = (id: number) =>
    fromNullable(vectorLayer.getSource()).chain((s) =>
      fromNullable(s.getFeatures().find((f) => f.getId() == id))
    );

  const layers = [
    new TileLayer({
      source: new TileWMS({
        url: "https://wms.atelier-cartographique.be/service",
        params: {
          LAYERS: "schaerbeek",
          TILED: true,
          SRS: "EPSG:3857",
          VERSION: "1.1.1",
        },
      }),
    }),

    vectorLayer,
  ];

  const map = new Map({
    target: "map",
    layers,
    view: getView(),
  });
  const infoWrapper = document.getElementById("feature-info")!;
  const legendWrapper = document.getElementById("legend_wrapper")!;
  const geocoderWrapper = document.getElementById("geocoder")!;

  const selectAction = (id: number | null) => {
    const selected = select.getFeatures();
    fromNullable(id).fold(
      () => selected.clear(),
      (id) => {
        selected.clear();
        getFeatureFromId(id).map((f) => selected.push(f));
        singleFeaturesInfo(selected.getArray());
        clearCategory();
      }
    );
  };

  const update = () => {
    updateLegend(legendWrapper);
    vectorLayer.setStyle(multipointStyle);
  };

  const updateGeocoder = () => {
    emptyElement(geocoderWrapper);
    geocoderWrapper.appendChild(geocoder(multiPoints, selectAction));
  };
  geocoderWrapper.appendChild(geocoder(multiPoints, selectAction));
  const featuresInfo = featuresInfoFactory(infoWrapper, dataContext);
  const singleFeaturesInfo = featureInfoFactory(infoWrapper, dataContext);

  map.addInteraction(select);
  select.on("select", (e: SelectEvent) => {
    const features = e.target.getFeatures().getArray();
    const nb = features.length;
    if (nb > 1) {
      setSelectedFeatures(features);
      featuresInfo(features, selectAction);
    } else if (nb === 1) {
      setSelectedFeatures(features);
      singleFeaturesInfo(features);
    } else {
      updateInfo(infoWrapper);
      setSelectedFeatures([]);
      set("category", null);
    }
  });
  observe("legend_expended", () => {
    emptyElement(infoWrapper);
    infoWrapper.append(singleFeaturesInfo(getSelectedFeatures()));
  });
  observe("category", update);
  observe("geocoder", updateGeocoder);
  observe("mapView", () => map.setView(getView()));
  observe("features", () => updateInfo(infoWrapper));
  update();
  updateInfo(infoWrapper);
};

(window as any).carte_proprete = main;
