import Feature from "ol/Feature";
import MultiPoint from "ol/geom/MultiPoint";

class None<A> {
  private tag = "none" as const;

  constructor() {}

  map<B>(f: (a: A) => B) {
    return new None<B>();
  }

  chain<B>(f: (a: A) => Option<B>) {
    return new None<B>();
  }

  fold<B>(dflt: () => B, f: (a: A) => B) {
    return dflt();
  }

  getOrElse(dflt: A) {
    return dflt;
  }
  isSome() {
    return false;
  }
  isNone() {
    return true;
  }
}

class Some<A> {
  private tag = "some" as const;

  constructor(private value: A) {}

  map<B>(f: (a: A) => B): Some<B> {
    return new Some(f(this.value));
  }

  chain<B>(f: (a: A) => Option<B>) {
    return f(this.value);
  }

  fold<B>(def: () => B, f: (a: A) => B) {
    return f(this.value);
  }

  getOrElse(_dflt: A) {
    return this.value;
  }
  isSome() {
    return true;
  }
  isNone() {
    return false;
  }
}

export const isOption = <T>(o: any): o is Option<T> =>
  o instanceof None || o instanceof Some;

export type Option<T> = None<T> | Some<T>;

export const some = <T>(val: T) => new Some(val);

export const none = new None();

export const fromNullable = <T>(val: T | null | undefined) => {
  if (val === undefined || val === null) {
    return new None<T>();
  }

  return some(val);
};

export const fromPredicate =
  <T>(fn: (t: T) => boolean) =>
  (val: T) => {
    if (fn(val)) {
      return some(val);
    }
    return new None<T>();
  };

export const notEmptyStr = fromPredicate<string>((arr) => arr.length > 0);
export const notEmpty = fromPredicate<any[]>((arr) => arr.length > 0);
export const boolToOpt = fromPredicate<boolean>((b) => b);
export const featureListNotEmpty = fromPredicate<Feature<MultiPoint>[]>(
  (arr) => arr.length > 0
);
