import { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import {
  foldGeocoder,
  getLang,
  getView,
  setSelectedFeatures,
  toolsGeocoder,
  unfoldGeocoder,
  updateGeocoderResponse,
  updateMapView,
} from "../state";
import {
  IToolGeocoder,
  IUgWsAddress,
  IUgWsResponse,
  IUgWsResult,
} from "./types";
import { BUTTON, DIV, DIVWithClick, INPUT } from "../dom";
import {
  featureListNotEmpty,
  fromNullable,
  fromPredicate,
  notEmpty,
} from "../option";
import { tr } from "../locale";
import { format } from "../index";
import { Feature } from "ol";
import { MultiPoint } from "ol/geom";
import { Coordinate } from "ol/coordinate";

const restServiceURL =
  "https://geoservices.irisnet.be/localization/Rest/Localize/getaddresses?";
// const restServiceReverseURL =
//   "https://geoservices.irisnet.be/localization/Rest/Localize/getaddressfromxy?";

const queryString = (o: { [k: string]: any }) => {
  return Object.keys(o).reduce((a, k) => {
    return `${a}&${k}=${o[k].toString()}`;
  }, "");
};

export const queryService = (
  address: string,
  language: string
): Promise<IUgWsResponse> => {
  const qs = queryString({
    spatialReference: "3857",
    language,
    address,
  });

  return fetch(`${restServiceURL}${qs}`)
    .then((response) => response.text())
    .then((text) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        return {
          result: [],
          error: true,
          status: "FailedToParse",
          version: "2.0",
        };
      }
    })
    .then((data: IUgWsResponse) => {
      if (data.error) {
        // on error result is the empty string, it breaks things down the line
        return {
          ...data,
          result: [],
        };
      }
      return data;
    });
};

// const updateAddress = (event: Event) => {
//   fromNullable(event.target).map((target) => updateGeocoderTerm(target.));
// };

const searchAddress = (address: string) => {
  // const state = toolsGeocoder();
  const lang = getLang();
  setSelectedFeatures([]);
  queryService(address, lang).then(updateGeocoderResponse).then(unfoldGeocoder);
};

const addressToString = (a: IUgWsAddress) => {
  return `${a.street.name} ${a.number}, ${a.street.postCode} ${a.street.municipality}`;
};

const withResponse = fromPredicate<IToolGeocoder>(
  (s) => !s.folded && s.serviceResponse !== null
);

const filterFeaturesFromStreet = (
  multipoints: Feature<MultiPoint>[],
  // featureCol: GeoJSONFeatureCollection,
  result: IUgWsResult
) => {
  return multipoints.filter((f) =>
    fromNullable(f.getProperties()["street"])
      .map((street) => {
        const propStreetName = street;
        const resultStreetName = result.address.street.name;
        return propStreetName.toLowerCase() === resultStreetName.toLowerCase();
      })
      .getOrElse(false)
  );
};
const renderResults = (
  state: IToolGeocoder,
  multipoints: Feature<MultiPoint>[],
  // actionsCollection: GeoJSONFeatureCollection,
  select: (id: number | null) => void
) =>
  withResponse(state).chain((s) => {
    const results = fromNullable(s.serviceResponse); // not needed but TS don't manage to infer this non-nullability
    return results.map((res) =>
      DIV(
        "tool-body results",
        DIV(
          "result-wrapper",
          res.result.map((result) => {
            const coords: Coordinate = [result.point.x, result.point.y];
            const filtered = filterFeaturesFromStreet(multipoints, result);
            const pointCoord: Coordinate = featureListNotEmpty(filtered)
              .chain((features) => {
                const f = fromNullable(features[0].getGeometry()).map((p) =>
                  p.getFirstCoordinate()
                );
                return f;
              })
              .getOrElse(coords);

            return DIV(
              "adress-result",
              DIVWithClick(
                "result",
                () => {
                  updateGeocoderResponse(null);
                  foldGeocoder();
                  updateMapView({
                    dirty: "geo",
                    center: pointCoord,
                    zoom: 17,
                    maxZoom: getView().getMaxZoom(),
                    minZoom: getView().getMinZoom(),
                  });
                  featureListNotEmpty(filtered).map((f) =>
                    select(f[0].getProperties()["fid"])
                  );
                },
                addressToString(result.address)
              )
            );
          })
        )
      )
    );
  });

const render = (
  multipoints: Feature<MultiPoint>[],
  // actionsCollection: GeoJSONFeatureCollection,
  select: (id: number | null) => void
) => {
  const state = toolsGeocoder();
  const results = renderResults(state, multipoints, select);
  const input = INPUT("input", "text", "adress", tr("address"));
  return DIV(
    "tool geocoder",
    DIV(
      "tool-body adress",
      input,
      BUTTON(
        "geocoder-button",
        () => {
          select(null);
          searchAddress(input.value);
        },
        tr("search")
      )
    ),
    results
  );
};

export default render;
