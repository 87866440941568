import { fromNullable } from "./option";
import { get } from "./state";

export type Record = [key: string, fr: string, nl: string];

const records: Record[] = [
  ["__empty__", "", ""],

  // texts of the templates
  [
    "page-title",
    "Carte des actions menées par la commune",
    "Kaart met acties van de gemeente",
  ],
  [
    "title",
    "Actions de propreté publique du service communal de Schaerbeek",
    "Openbare netheid acties door de gemeentedienst van Schaarbeek",
  ],
  [
    "generic-info",
    "Cette cartographie reprend les actions en termes de propreté publique réalisées par le service communal Schaerbeek Propreté et Espaces verts. Elle présente pour chaque rue les actions de traitement (nettoiement, enlèvement des dépôts clandestins, balayage), de sensibilisation et de répression. Pour des raisons de confidentialité, les informations sont données par rue, sans précision du numéro. Les voiries dont le nettoiement est géré par la Région (Bruxelles Propreté) sont indiquées en jaune. Sur celle-ci, les données n’apparaissent pas car elles ne sont pas communiquées par les instances régionales. Pour plus d’information n’hésitez pas à contacter notre centrale d’appel au 0800 939 88.",
    "Deze kaart toont de initiatieven voor openbare netheid van de gemeentelijke dienst Schaarbeek Openbare netheid en Groene ruimtes. Ze toont de behandeling (reiniging, verwijdering van illegale stortplaatsen, vegen), sensibilisering en handhaving in elke straat. Om vertrouwelijkheidsredenen wordt de informatie per straat gegeven, zonder vermelding van het nummer. Wegen waarvan de reiniging wordt beheerd door het gewest (Net Brussel) worden in het geel weergegeven. In dit geval worden de gegevens niet weergegeven omdat ze niet worden meegedeeld door de gewestelijke autoriteiten. Voor meer informatie kunt u contact opnemen met ons callcenter op 0800 939 88.",
  ],
  ["backgroud-map", "Données du fond de plan", "Basiskaart"],
  [
    "credit",
    "Cartographie et visionneuse de données conçues par",
    "Cartografie en data viewer ontworpen door",
  ],
  [
    "from-previous-form",
    "Pour repartir d'un encodage en cours, choisissez parmi:",
    "Om vanuit een bestaand formulier te starten, kies één van de volgende:",
  ],
  ["form-name", "Nom du formulaire", "formuliernaam"],
  [
    "time-choice",
    "Choisissez l'intervalle de temps pour ces actions",
    "Kies het tijdsinterval voor deze acties",
  ],
  [
    "make-backup",
    "Créer une sauvegarde temporaire",
    "Een tijdelijke back-up maken",
  ],
  ["send-data", "Envoyer les données", "Gegevens verzenden"],

  // Texts in admin
  ["intervention", "Interventions", "Interventies"],
  ["taxe", "PV", "Belastingen"],
  ["balayage", "Balayages", "Vegen"],
  ["mediateur", "Actions des médiateurs", "Bemiddelingsacties"],
  ["container", "Campagne de déchetterie mobile", "Mobiele afvalinzameling"],
  [
    "pv-description",
    "Action de l’équipe de recherche et verbalisation qui a donné lieu à un PV (le déposant a pu être identifié). Environ 10% des actions de la cellule répression amènent à une identification",
    "Optreden van het recherche- en bekeuringsteam die hebben geleid tot een bekeuring (de overtreder is geïdentificeerd). Ongeveer 10% van de acties van de wetshandhavingseenheid leidde tot identificatie.",
  ],
  [
    "intervention-description",
    "Action de propreté réalisée par nos équipes : ramassage d’un encombrant, ramassage d’un sac mal sorti, nettoyage de la voie publique, vidange d’un avaloir, enquête de la cellule verbalisation. Ces actions sont réalisées en plus des missions habituelles de balayage, et d’entretien de l’espace public.",
    "Netheidacties uitgevoerd door onze teams: verwijderen van grofvuil, verwijderen van verkeerd geplaatste zakken, schoonmaken van de openbare weg, legen van een goot, onderzoeken uitgevoerd door de bekeuringseenheid. Deze acties worden uitgevoerd naast het gebruikelijke vegen en onderhoud van de openbare ruimte.",
  ],
  [
    "container-description",
    "Site où une campagne de déchetterie mobile a eu lieu.",
    "Locatie waar een mobiele afvalinzamelingsactie plaatsvond.",
  ],
  [
    "sweeps-description",
    "Moyenne mensuelle du nombre de balayage de la rue",
    "Gemiddeld aantal straatveegbeurten per maand",
  ],

  // texts translated in the code
  ["description", "description", "bescrijving"],
  ["count", "nombre d'action", "aantal acties"],
  ["actions", "actions", "acties"],
  [
    "helptext:selectOnMap",
    "Pour accéder aux données, entrez une adresse, sélectionnez un élément sur la carte",
    "Vul een straatnaam in of selecteer een element op de kaart om de gegevens weer te geven",
  ],
  ["prevention", "Prévention", "Preventie"],
  ["gestion", "Gestion", "Beheer"],
  ["address", "Nom de rue, n°", "Adres"],
  ["search", "Chercher", "Zoeken"],
  ["last_description", "Dernière action menée", "Laatste actie"],
  [
    "regional_street",
    "Rues gérées par la Région",
    "Straten in beheer van de regio",
  ],
  ["see_more", "Afficher les détails", "Details tonen"],
  ["see_less", "Masquer les détails", "Details verbergen"],
  ["actions number", "Nombre d'actions", "Aantal acties"],
  [
    "regional_street_info",
    "Les données n’apparaissent pas sur ces voiries car elles ne sont pas communiquées par les instances régionales.",
    // "Les voiries dont le nettoiement est géré par la Région (Bruxelles Propreté) sont indiquées en jaune. Sur celle-ci, les données n’apparaissent pas car elles ne sont pas communiquées par les instances régionales.",
    "Gegevens voor deze wegens worden niet weergegeven omdat ze niet worden meegedeeld door de gewestelijke autoriteiten.",
  ],
];

export const tr = (key: string) =>
  fromNullable(records.find(([recKey]) => key === recKey))
    .map(([_, fr, nl]) => {
      const truc = get("lang");
      return get("lang") === "fr" ? fr : nl;
    })
    .getOrElse(key);

export const trMulti = (key: string) =>
  key
    .split(",")
    .map((k) => tr(k.trim()))
    .join(", ");

// export const fromRecord = ([_, fr, nl]: Record): string =>
//   get("lang") === "fr" ? fr : nl;

// TODO: unify both linguarecord and record ;)
export interface LinguaRecord {
  fr: string;
  nl: string;
}
export const fromLinguaRecord = (linguaRecord: LinguaRecord) => {
  return fromNullable(linguaRecord)
    .map((lr) => {
      const lang = get("lang");
      if (lang == "fr" || lang == "nl") {
        return lr[lang];
      }
      return "~ Language not known";
    })
    .getOrElse("~ Record undefined");
};
